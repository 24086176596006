import loader from "./loader4.gif"
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="h_heading">
          HYGIHAAVEN
        </h1>
        <img src={loader} alt="logo" className="card" />
      <p>WEBSITE UNDER CONSTRUCTION</p>
      </header>
    </div>
  );
}

export default App;
